<template>
  <v-dialog
    v-model="showModalDetailCart"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="closeModal">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Detalle del Carro de compras</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <template>
        <v-container fluid grid-list-md style="padding: 1.5% 2% !important">
          <template>
            <v-layout row wrap>
              <v-flex xs12 sm8>
                <!-- Vista resumen carro -->
                <v-card
                  class="resumen-carro"
                  style="
                    margin-bottom: 25px !important;
                    padding-bottom: 15px !important;
                  "
                >
                  <v-toolbar color="light-blue" dark>
                    <v-toolbar-title>Resumen del Carro:</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>

                  <v-layout row>
                    <v-flex xs12 sm4>
                      <v-list dense two-line style="padding: 0 0 0 25px">
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >N° de Orden</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              >#{{ detailCart.numero_orden }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >N° de Boleta</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              v-if="detailCart.pago.num_documento != null"
                              >{{ detailCart.pago.num_documento }}
                              <span v-if="detailCart.pago.file_path">
                                -
                                <a
                                  :href="detailCart.pago.file_path"
                                  target="_blank"
                                  >Descargar boleta</a
                                ></span
                              ></v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Subtotal</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              >${{
                                formatMoney(detailCart.subtotal)
                              }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Descuento</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              >${{
                                formatMoney(detailCart.monto_descuento)
                              }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Valor despacho</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              >${{
                                formatMoney(detailCart.precio_despacho)
                              }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title>Total</v-list-tile-sub-title>
                            <v-list-tile-title
                              >${{
                                formatMoney(detailCart.precio_total)
                              }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                    </v-flex>
                    <v-divider vertical></v-divider>
                    <v-flex xs12 sm4>
                      <v-list dense two-line style="padding: 0 25px">
                        <v-list-tile ripple>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Estado del Pago</v-list-tile-sub-title
                            >
                            <v-list-tile-title>{{
                              detailCart.pago.estado_pago
                            }}</v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Forma de Pago</v-list-tile-sub-title
                            >
                            <v-list-tile-title>{{
                              detailCart.pago.tipo
                            }}</v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Monto pagado</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              >${{
                                formatMoney(detailCart.pago.monto_pagado)
                              }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>

                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Código de autorización</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              v-if="detailCart.pago.codigo_autorizacion != null"
                              >{{
                                detailCart.pago.codigo_autorizacion
                              }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>

                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >N° de tarjeta</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              v-if="detailCart.pago.numero_tarjeta != null"
                              >{{
                                detailCart.pago.numero_tarjeta
                              }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Código cupón</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              v-if="detailCart.cupon != null"
                              >{{ detailCart.cupon.codigo }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                    </v-flex>
                    <v-divider vertical></v-divider>
                    <v-flex xs12 sm4>
                      <v-list dense two-line style="padding: 0 25px">
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Fecha y hora</v-list-tile-sub-title
                            >
                            <v-list-tile-title>{{
                              detailCart.created_at
                            }}</v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Destino</v-list-tile-sub-title
                            >
                            <v-list-tile-title>{{
                              detailCart.ciudad
                            }}</v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Courier</v-list-tile-sub-title
                            >
                            <v-list-tile-title>{{
                              detailCart.courier.nombre
                            }} - {{ detailCart.courier_descripcion }}</v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >N° de seguimiento</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              v-if="detailCart.num_seguimiento != null"
                              >{{
                                detailCart.num_seguimiento
                              }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Peso total</v-list-tile-sub-title
                            >
                            <v-list-tile-title>{{
                              detailCart.peso_total
                            }}</v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Documento Tributario</v-list-tile-sub-title
                            >
                            <v-list-tile-title v-if="detailCart.factura"
                              >Factura</v-list-tile-title
                            >
                            <v-list-tile-title v-else>Boleta</v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                    </v-flex>
                  </v-layout>
                  <v-divider v-if="detailCart.notas != null"></v-divider>
                  <v-layout row wrap v-if="detailCart.notas != null">
                    <v-flex xs12 sm12>
                      <v-list style="padding: 0 25px">
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Notas al pedido:</v-list-tile-sub-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                      <p style="padding: 0px 42px; font-size: 12px">
                        {{ detailCart.notas }}
                      </p>
                    </v-flex>
                  </v-layout>
                  <v-divider v-if="detailCart.factura != null"></v-divider>
                  <v-layout row wrap v-if="detailCart.factura != null">
                    <v-flex xs12 sm12>
                      <v-list style="padding: 0 25px">
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Datos para Factura:</v-list-tile-sub-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                      <p
                        style="padding: 0px 42px; font-size: 12px"
                        v-html="detailCart.factura"
                      ></p>
                    </v-flex>
                  </v-layout>
                </v-card>
                <!-- Vista movil resumen carro -->
                <v-card
                  class="resumen-carro-movil"
                  style="margin-top: 20px; padding-bottom: 25px !important"
                >
                  <v-toolbar color="light-blue" dark>
                    <v-toolbar-title>Resumen del Carro:</v-toolbar-title>
                  </v-toolbar>

                  <v-layout row>
                    <v-flex xs12 sm12>
                      <v-list two-line style="padding: 15px 10px">
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >N° de Orden</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              >#{{ detailCart.numero_orden }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >N° de Boleta</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              v-if="detailCart.pago.num_documento != null"
                              >{{ detailCart.pago.num_documento }}
                              <span v-if="detailCart.pago.file_path">
                                -
                                <a
                                  :href="detailCart.pago.file_path"
                                  target="_blank"
                                  >Descargar boleta</a
                                ></span
                              ></v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Subtotal</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              >${{
                                formatMoney(detailCart.subtotal)
                              }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Descuento</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              >${{
                                formatMoney(detailCart.monto_descuento)
                              }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Valor despacho</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              >${{
                                formatMoney(detailCart.precio_despacho)
                              }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title>Total</v-list-tile-sub-title>
                            <v-list-tile-title
                              >${{
                                formatMoney(detailCart.precio_total)
                              }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile ripple>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Estado del Pago</v-list-tile-sub-title
                            >
                            <v-list-tile-title>{{
                              detailCart.pago.estado_pago
                            }}</v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Forma de Pago</v-list-tile-sub-title
                            >
                            <v-list-tile-title>{{
                              detailCart.pago.tipo
                            }}</v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Monto pagado</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              >${{
                                formatMoney(detailCart.pago.monto_pagado)
                              }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>

                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Código de autorización</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              v-if="detailCart.pago.codigo_autorizacion != null"
                              >{{
                                detailCart.pago.codigo_autorizacion
                              }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>

                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >N° de tarjeta</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              v-if="detailCart.pago.numero_tarjeta != null"
                              >{{
                                detailCart.pago.numero_tarjeta
                              }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Código cupón</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              v-if="detailCart.cupon != null"
                              >{{ detailCart.cupon.codigo }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Fecha y hora</v-list-tile-sub-title
                            >
                            <v-list-tile-title>{{
                              detailCart.created_at
                            }}</v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Destino</v-list-tile-sub-title
                            >
                            <v-list-tile-title>{{
                              detailCart.ciudad
                            }}</v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Courier</v-list-tile-sub-title
                            >
                            <v-list-tile-title>{{ detailCart.courier.nombre }} - {{ detailCart.courier_descripcion }}
                              </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >N° de seguimiento</v-list-tile-sub-title
                            >
                            <v-list-tile-title
                              v-if="detailCart.num_seguimiento != null"
                              >{{
                                detailCart.num_seguimiento
                              }}</v-list-tile-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Peso total</v-list-tile-sub-title
                            >
                            <v-list-tile-title>{{
                              detailCart.peso_total
                            }}</v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Documento Tributario</v-list-tile-sub-title
                            >
                            <v-list-tile-title v-if="detailCart.factura"
                              >Factura</v-list-tile-title
                            >
                            <v-list-tile-title v-else>Boleta</v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                      <v-list v-if="detailCart.notas" style="padding: 0 10px">
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Notas al pedido:</v-list-tile-sub-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                      <p
                        v-if="detailCart.notas"
                        style="padding: 0px 25px; font-size: 12px"
                      >
                        {{ detailCart.notas }}
                      </p>
                      <v-list v-if="detailCart.factura" style="padding: 0 10px">
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title
                              >Datos para Factura:</v-list-tile-sub-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                      <p
                        v-if="detailCart.factura"
                        style="padding: 0px 25px; font-size: 12px"
                        v-html="detailCart.factura"
                      ></p>
                    </v-flex>
                  </v-layout>

                  <!-- <v-layout row wrap v-if="detailCart.notas != null">
                    <v-flex xs12 sm12>
                      <v-list style="padding: 0 25px;">
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-sub-title>Notas al pedido:</v-list-tile-sub-title>                            
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                      <p style="padding: 0px 42px; font-size: 12px;">{{ detailCart.notas }}</p>
                    </v-flex>
                  </v-layout> -->
                </v-card>
                <!-- Detalle carro -->
                <v-card
                  style="
                    margin-top: 15px;
                    padding-bottom: 25px !important;
                    margin-bottom: 15px;
                  "
                >
                  <v-toolbar color="grey darken-1" dark card>
                    <v-toolbar-title>Detalle del Carro</v-toolbar-title>
                    <v-spacer />
                  </v-toolbar>

                  <v-layout row>
                    <v-flex xs12>
                      <v-data-table
                        :headers="[
                          { text: 'SKU' },
                          { text: 'Producto' },
                          { text: 'Item' },
                          { text: 'Precio' },
                          { text: '% Desc' },
                          { text: 'Cant' },
                          { text: 'Subtotal' },
                        ]"
                        :items="detailCart.detalles"
                        :rows-per-page-items="[100]"
                        class="elevation-1"
                      >
                        <tr slot="items" slot-scope="props">
                          <td class="px-3">
                            {{ props.item.item_producto.SKU }}
                          </td>
                          <td class="px-3">
                            {{ props.item.nombre_producto }}
                          </td>
                          <td class="px-3">
                            {{ props.item.nombre_item }}
                          </td>
                          <td class="px-3 text-xs-center">
                            {{ formatMoney(props.item.precios) }}
                          </td>
                          <td class="px-3 text-xs-center">
                            {{ formatMoney(props.item.descuento) }}
                          </td>
                          <td class="px-3 text-xs-center">
                            {{ props.item.cantidad }}
                          </td>
                          <td class="px-3 text-xs-center">
                            {{ formatMoney(props.item.subtotal) }}
                          </td>
                        </tr>
                      </v-data-table>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex class="panel-right" xs12 sm4>
                <v-card
                  style="
                    margin-bottom: 15px !important;
                    padding-bottom: 15px !important;
                  "
                >
                  <v-toolbar color="cyan" dark>
                    <v-toolbar-title>Datos del Cliente</v-toolbar-title>

                    <v-spacer></v-spacer>
                  </v-toolbar>

                  <v-list two-line dense>
                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon>person</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-sub-title>Nombre</v-list-tile-sub-title>
                        <v-list-tile-title>{{
                          detailCart.nombre
                        }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon>person</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-sub-title>Apellidos</v-list-tile-sub-title>
                        <v-list-tile-title>{{
                          detailCart.apellidos
                        }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon>mail</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-sub-title>E-mail</v-list-tile-sub-title>
                        <v-list-tile-title>{{
                          detailCart.cliente.email
                        }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon>phone</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-sub-title>Teléfono</v-list-tile-sub-title>
                        <v-list-tile-title>{{
                          detailCart.telefono
                        }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon>location_on</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-sub-title>Dirección</v-list-tile-sub-title>
                        <v-list-tile-title>{{
                          detailCart.direccion
                        }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile v-if="detailCart.ubicacion">
                      <v-list-tile-avatar>
                        <v-icon>location_on</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-sub-title
                          >Villa / Población / Otro</v-list-tile-sub-title
                        >
                        <v-list-tile-title>{{
                          detailCart.ubicacion
                        }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon>map</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-sub-title>Ciudad</v-list-tile-sub-title>
                        <v-list-tile-title>{{
                          detailCart.ciudad
                        }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list>
                </v-card>
                <v-card
                  style="
                    margin-bottom: 15px !important;
                    padding-bottom: 15px !important;
                  "
                >
                  <v-toolbar color="cyan" dark>
                    <v-toolbar-title>Acciones</v-toolbar-title>

                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-list two-line dense>
                    <v-list-tile>
                      <v-list-tile-avatar v-if="e6 > 1">
                        <v-icon color="green">check_circle</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-avatar v-else>
                        <v-icon color="pink">close</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-sub-title
                          >Etapa actual</v-list-tile-sub-title
                        >
                        <v-list-tile-title>{{
                          detailCart.etapaActual.nombre
                        }}</v-list-tile-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-dialog
                          v-model="dialog"
                          max-width="600px"
                          transition="dialog-bottom-transition"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn fab dark small color="blue" v-on="on">
                              <v-icon dark>edit</v-icon></v-btn
                            >
                          </template>
                          <v-card>
                            <v-toolbar
                              style="
                                margin-bottom: 15px;
                                letter-spacing: 1.8px;
                                font-size: 1.5rem;
                                flex: unset;
                              "
                              color="primary"
                              dark
                              text-align="center"
                              ><v-spacer></v-spacer>
                              <h4 style="text-align: center">
                                Listado de etapas
                              </h4>
                              <v-spacer></v-spacer>
                              <v-btn icon dark @click="dialog = false">
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-stepper
                                v-model="e6"
                                vertical
                                style="border: 0"
                              >
                                <v-stepper-step :complete="e6 > 1" step="1">
                                  Pendiente de pago
                                </v-stepper-step>

                                <v-stepper-content step="1">
                                </v-stepper-content>

                                <v-stepper-step :complete="e6 > 2" step="2"
                                  >Pagado</v-stepper-step
                                >

                                <v-stepper-step :complete="e6 > 3" step="3"
                                  >En preparacion</v-stepper-step
                                >
                                <v-stepper-content step="3">
                                  <v-btn
                                    small
                                    color="success"
                                    @click="sendEmailEtapa(3)"
                                    >Enviar Email</v-btn
                                  >
                                </v-stepper-content>

                                <v-stepper-step :complete="e6 > 4" step="4"
                                  >En despacho</v-stepper-step
                                >
                                <v-stepper-content step="4">
                                  <v-btn
                                    v-if="
                                      detailCart.courier.nombre == 'Chilexpress'
                                    "
                                    color="success"
                                    small
                                    @click="sendEmailEtapa(4)"
                                    >Enviar Email</v-btn
                                  >
                                </v-stepper-content>
                                <v-stepper-step step="5"
                                  >Recepcionado por cliente</v-stepper-step
                                >
                              </v-stepper>
                              <v-btn
                                v-if="e6 > 1"
                                class="ma-0"
                                block
                                color="primary"
                                @click="nextStep"
                                style="margin: 10px 0px !important"
                                >Avanzar etapa</v-btn
                              >
                              <v-btn
                                v-if="e6 > 1"
                                class="ma-0"
                                block
                                color="alert"
                                @click="retroceder"
                                style="margin: 10px"
                                >Retroceder etapa</v-btn
                              >
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                flat
                                @click="dialog = false"
                                >Cerrar</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-tile-action>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile v-if="e6 > 1">
                      <v-list-tile-avatar v-if="detailCart.pago.num_documento">
                        <v-icon color="green">check_circle</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-avatar v-else>
                        <v-icon>info</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content v-if="detailCart.pago.num_documento">
                        <v-list-tile-sub-title
                          >N° de Boleta</v-list-tile-sub-title
                        >
                        <v-list-tile-title>{{
                          detailCart.pago.num_documento
                        }}</v-list-tile-title>
                      </v-list-tile-content>

                      <v-list-tile-content v-else>
                        <v-list-tile-sub-title
                          >N° de Boleta</v-list-tile-sub-title
                        >
                        <v-list-tile-title>Pendiente</v-list-tile-title>
                      </v-list-tile-content>

                      <v-list-tile-action>
                        <v-dialog
                          v-model="dialogBoleta"
                          max-width="600px"
                          transition="dialog-bottom-transition"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              fab
                              dark
                              small
                              color="blue"
                              v-on="on"
                              @click="boletaFormOpen"
                            >
                              <v-icon dark>edit</v-icon></v-btn
                            >
                          </template>
                          <v-card>
                            <v-toolbar
                              style="
                                margin-bottom: 15px;
                                letter-spacing: 1.8px;
                                font-size: 1.5rem;
                                flex: unset;
                              "
                              color="primary"
                              dark
                              text-align="center"
                              ><v-spacer></v-spacer>
                              <h4 style="text-align: center">
                                Documento Tributario
                              </h4>
                              <v-spacer></v-spacer>
                              <v-btn icon dark @click="closeBoleta">
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-list dense>
                                <v-list-tile>
                                  <v-list-tile-content style=""
                                    >Ingresar N° de Boleta o
                                    Factura</v-list-tile-content
                                  >
                                </v-list-tile>
                              </v-list>
                              <v-layout row wrap style="padding: 10px">
                                <v-flex md7 sm7 xs12>
                                  <v-text-field
                                    dense
                                    v-model="form.num_documento"
                                    label="Num de Boleta o Factura"
                                    outline
                                    hide-details
                                  />
                                </v-flex>
                                <v-flex md5 sm5 xs12>
                                  <v-btn
                                    color="primary"
                                    v-if="!this.detailCart.pago.num_documento"
                                    @click="generarBoleta"
                                    :loading="loadingBoleta"
                                    >Generar Boleta</v-btn
                                  >
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <!-- <v-btn
                                v-if="form.num_documento"
                                color="primary"
                                @click="sendDataDespacho"
                                :loading="loadingBoleta"
                                >Guardar</v-btn> -->
                              <v-btn
                                color="primary"
                                @click="sendDataDespacho"
                                :loading="loadingBoleta"
                                >Guardar</v-btn
                              >
                              <v-btn
                                color="blue darken-1"
                                flat
                                @click="closeBoleta"
                                >Cerrar</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-tile-action>
                    </v-list-tile>
                    <v-list-tile
                      v-if="detailCart.etapaActual.id === 1 && userIsAdmin"
                    >
                      <v-list-tile-avatar v-if="detailCart.etapaActual.id > 1">
                        <v-icon color="green">check_circle</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-avatar v-else>
                        <v-icon>info</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-sub-title
                          >Pago manual</v-list-tile-sub-title
                        >
                        <v-list-tile-title>Pendiente</v-list-tile-title>
                      </v-list-tile-content>

                      <v-list-tile-action>
                        <v-dialog
                          v-model="dialogPago"
                          max-width="600px"
                          transition="dialog-bottom-transition"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn fab dark small color="blue" v-on="on">
                              <v-icon dark>edit</v-icon></v-btn
                            >
                          </template>
                          <v-card>
                            <v-toolbar
                              style="
                                margin-bottom: 15px;
                                letter-spacing: 1.8px;
                                font-size: 1.5rem;
                                flex: unset;
                              "
                              color="pink"
                              dark
                              text-align="center"
                              ><v-spacer></v-spacer>
                              <h4 style="text-align: center">Pago Manual</h4>
                              <v-spacer></v-spacer>
                              <v-btn icon dark @click="dialogPago = false">
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <p>
                                Información: La función de pago manual permite
                                aceptar pagos fuera de los medios de pago
                                disponible de la plataforma. Una vez ingresado
                                el pago, se actualizan la etapa y se envía un
                                email al cliente.
                              </p>

                              <v-layout
                                row
                                wrap
                                style="padding: 10px"
                                class="text-xs-center"
                              >
                                <v-flex xs12>
                                  <p>
                                    Monto pendiente:
                                    {{ formatMoney(detailCart.precio_total) }}
                                  </p>
                                  <v-text-field
                                    dense
                                    v-model="monto"
                                    label="Monto pagado por cliente"
                                    outline
                                  />
                                </v-flex>
                                <v-flex xs12>
                                  <v-textarea
                                    outline
                                    v-model="justificacion"
                                    name="justificacion"
                                    label="Ingresar justificación (Obligatorio)"
                                  ></v-textarea>
                                </v-flex>
                              </v-layout>
                              <small
                                >* Utilizar esta opción en casos
                                excepcionales.</small
                              >
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                v-if="monto && justificacion"
                                color="primary"
                                :loading="pagoManualLoading"
                                @click="pagarCarro"
                                >Guardar</v-btn
                              >
                              <v-btn
                                color="blue darken-1"
                                flat
                                @click="dialogPago = false"
                                >Cerrar</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-tile-action>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile
                      v-if="
                        e6 > 2 &&
                        (detailCart.courier.nombre == 'Chilexpress' ||
                          detailCart.courier.nombre == 'Wareclouds')
                      "
                    >
                      <v-list-tile-avatar
                        v-if="
                          detailCart.num_seguimiento &&
                          (detailCart.courier.nombre == 'Chilexpress' ||
                            detailCart.courier.nombre == 'Wareclouds')
                        "
                      >
                        <v-icon color="green">check_circle</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-avatar
                        v-else-if="
                          !detailCart.num_seguimiento &&
                          (detailCart.courier.nombre == 'Chilexpress' ||
                            detailCart.courier.nombre == 'Wareclouds')
                        "
                      >
                        <v-icon>info</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content
                        v-if="
                          detailCart.num_seguimiento &&
                          (detailCart.courier.nombre == 'Chilexpress' ||
                            detailCart.courier.nombre == 'Wareclouds')
                        "
                      >
                        <v-list-tile-sub-title
                          >OT de
                          {{ detailCart.courier.nombre }}</v-list-tile-sub-title
                        >
                        <v-list-tile-title>{{
                          detailCart.num_seguimiento
                        }}</v-list-tile-title>
                      </v-list-tile-content>

                      <v-list-tile-content
                        v-else-if="
                          (!detailCart.num_seguimiento &&
                            detailCart.courier.nombre == 'Chilexpress') ||
                          detailCart.courier.nombre == 'Wareclouds'
                        "
                      >
                        <v-list-tile-sub-title
                          >OT de
                          {{ detailCart.courier.nombre }}</v-list-tile-sub-title
                        >
                        <v-list-tile-title>Pendiente</v-list-tile-title>
                      </v-list-tile-content>

                      <v-list-tile-action>
                        <v-dialog
                          v-model="dialogChilexpress"
                          max-width="600px"
                          transition="dialog-bottom-transition"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              fab
                              dark
                              small
                              color="blue"
                              v-on="on"
                              @click="abrirOT"
                            >
                              <v-icon dark>edit</v-icon></v-btn
                            >
                          </template>
                          <v-card>
                            <v-toolbar
                              style="
                                margin-bottom: 15px;
                                letter-spacing: 1.8px;
                                font-size: 1.5rem;
                                flex: unset;
                              "
                              color="primary"
                              dark
                              text-align="center"
                              ><v-spacer></v-spacer>
                              <h4 style="text-align: center">
                                OT de {{ detailCart.courier.nombre }}
                              </h4>
                              <v-spacer></v-spacer>
                              <v-btn
                                icon
                                dark
                                @click="dialogChilexpress = false"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text style="padding: 15px 5% 35px">
                              <h4 style="margin: 10px 0 20px">
                                Generación de OT de
                                {{ detailCart.courier.nombre }}
                                <span
                                  v-if="
                                    detailCart.courier.nombre === 'Chilexpress'
                                  "
                                  >e impresión de etiquetado.</span
                                >
                              </h4>
                              <v-layout row wrap>
                                <v-flex
                                  md12
                                  sm12
                                  xs12
                                  style="margin: 10px 0 35px !important"
                                >
                                  <v-text-field
                                    justify-center
                                    dense
                                    hide-details
                                    v-model="form.num_seguimiento"
                                    label="Numero de seguimiento"
                                    outline
                                  />
                                </v-flex>
                                <v-flex
                                  xs12
                                  sm7
                                  md7
                                  v-if="this.e6 > 2 && form.num_seguimiento"
                                >
                                  <v-autocomplete
                                    v-model="tipo"
                                    :items="tipos"
                                    dense
                                    hide-details
                                    outline
                                    clearable
                                    small-chips
                                    item-text="text"
                                    item-value="value"
                                    label="Tipo de Etiqueta"
                                  />
                                </v-flex>
                                <v-flex
                                  md4
                                  sm4
                                  xs12
                                  v-if="
                                    this.e6 > 2 &&
                                    form.num_seguimiento &&
                                    detailCart.courier.nombre === 'Chilexpress'
                                  "
                                >
                                  <v-btn
                                    color="primary"
                                    @click="generarEtiqueta"
                                    >Generar Etiqueta
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="success"
                                @click="generarOT"
                                v-if="!this.detailCart.num_seguimiento"
                                :loading="loadingOT"
                                >Generar OT</v-btn
                              >
                              <v-btn
                                color="primary"
                                @click="guardarOT"
                                :loading="loadingOT"
                                >Guardar OT</v-btn
                              >
                              <v-btn
                                color="blue darken-1"
                                flat
                                @click="dialogChilexpress = false"
                                >Cerrar</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-tile-action>
                    </v-list-tile>
                    <v-divider
                      v-if="
                        e6 > 2 &&
                        detailCart.courier.modelo == 'RETIRO_TIENDA_PUNT'
                      "
                    ></v-divider>
                    <v-list-tile
                      v-if="
                        e6 > 2 &&
                        detailCart.courier.modelo == 'RETIRO_TIENDA_PUNT'
                      "
                    >
                      <v-list-tile-avatar>
                        <v-icon color="green">info</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-sub-title
                          >Pedido listo para retiro</v-list-tile-sub-title
                        >
                        <v-list-tile-title
                          >Notificar vía email</v-list-tile-title
                        >
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-btn
                          :loading="loadingEmail"
                          fab
                          dark
                          small
                          color="blue"
                          v-on="on"
                          @click="retiroTiendaEmail"
                        >
                          <v-icon dark>mail</v-icon></v-btn
                        >
                      </v-list-tile-action>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon color="red">info</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-title>Anular orden</v-list-tile-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-dialog
                          v-model="dialogEliminar"
                          max-width="600px"
                          transition="dialog-bottom-transition"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn fab dark small color="pink" v-on="on">
                              <v-icon dark>delete</v-icon></v-btn
                            >
                          </template>
                          <v-card>
                            <v-toolbar
                              style="
                                margin-bottom: 15px;
                                letter-spacing: 1.8px;
                                font-size: 1.5rem;
                                height: 100px;
                                padding: 25px 20px;
                                flex: unset;
                              "
                              color="pink"
                              dark
                              text-align="center"
                              ><v-spacer></v-spacer>
                              <h4 style="text-align: center">Anular orden</h4>
                              <v-spacer></v-spacer>
                              <v-btn icon dark @click="dialogEliminar = false">
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text class="text-center">
                              <v-spacer></v-spacer>
                              <h4>
                                ¿Está seguro que desea anular la ORDEN #{{
                                  detailCart.numero_orden
                                }}?
                              </h4>
                              <br />
                              <p>
                                IMPORTANTE: La Orden sólo se puede anular si el
                                carro se encuentra en la etapa "PAGADO".
                              </p>

                              <v-spacer></v-spacer>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="pink"
                                dark
                                @click="anularCarro()"
                                :loading="anularCarroLoading"
                                >Anular</v-btn
                              >
                              <v-btn
                                color="blue darken-1"
                                flat
                                @click="dialogEliminar = false"
                                >Cerrar</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-tile-action>
                    </v-list-tile>
                  </v-list>
                </v-card>
                <v-card
                  style="
                    margin-bottom: 15px !important;
                    padding-bottom: 15px !important;
                  "
                >
                  <v-toolbar color="cyan" dark>
                    <v-toolbar-title>Log de Pagos</v-toolbar-title>

                    <v-spacer></v-spacer>
                  </v-toolbar>

                  <v-list two-line dense>
                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon>info</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-title>Ver log</v-list-tile-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-dialog
                          v-model="dialogLog"
                          max-width="600px"
                          transition="dialog-bottom-transition"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn fab dark small color="blue" v-on="on">
                              <v-icon dark>edit</v-icon></v-btn
                            >
                          </template>
                          <v-card>
                            <v-toolbar
                              style="
                                margin-bottom: 15px;
                                letter-spacing: 1.8px;
                                font-size: 1.5rem;
                                flex: unset;
                              "
                              color="pink"
                              dark
                              text-align="center"
                              ><v-spacer></v-spacer>
                              <h4 style="text-align: center">Log de Pagos</h4>
                              <v-spacer></v-spacer>
                              <v-btn icon dark @click="dialogLog = false">
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text style="padding: 2% 5%">
                              <h4>
                                Información sobre el registro de pago con
                                Tarjeta de Crédito, Pago vía Transferencia o
                                justificación para cambiar el estado del carro
                                de Pendiente a Pagado.
                              </h4>
                              <br /><br />
                              <pre v-if="detailCart.pago.tipo === 'WEBPAY'">{{
                                detailCart.pago.notas | pretty
                              }}</pre>
                              <p
                                v-if="detailCart.pago.tipo === 'TRANSFERENCIA'"
                              >
                                {{ detailCart.pago.notas }}
                              </p>
                              <!-- <pre style="padding: 0 5%;">{{ detailCart.pago.notas | pretty }}</pre>                              -->
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                flat
                                @click="dialogLog = false"
                                >Cerrar</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-tile-action>
                    </v-list-tile>
                  </v-list>
                </v-card>
              </v-flex>
            </v-layout>
          </template>
        </v-container>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import axios from "axios";
import { baseUrlAPI } from "@/config/api";
const HOST = baseUrlAPI;

export default {
  components: {
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    NotPermission: () => import("@/views/errors/NotPermission"),
  },
  props: ["carts", "e6", "id"],
  data() {
    return {
      activeName: "first",
      tab: "",
      loadingEmail: false,
      loadingDetalle: false,
      dialog: false,
      dialogLog: false,
      dialogBoleta: false,
      dialogPago: false,
      dialogChilexpress: false,
      dialogEliminar: false,
      pagoManualLoading: false,
      anularCarroLoading: false,
      sheet: false,
      loadingOT: false,
      loadingBoleta: false,
      monto: "",
      justificacion: "",
      form: {
        num_seguimiento: "",
        num_documento: "",
      },
      tipo: "",
      tipos: [
        // { value: "ZEBRA", text: "Impresora Zebra" },
        { value: "IMAGEN", text: "impresora normal " },
      ],
    };
  },
  filters: {
    pretty: function (value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    },
  },
  computed: {
    ...mapState({
      detailCart: (state) => state.detailCart.detailCart,
      showModalDetailCart: (state) => state.carts.showModalDetailCart,
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user",
      userIsAdmin: "auth/userIsAdmin",
    }),
  },
  // created() {
  //   this.getDetailCart({ cartId: this.id });
  // },
  async mounted() {
    this.form.num_seguimiento = this.detailCart.num_seguimiento;
    this.form.num_documento = this.detailCart.pago.num_documento;
    this.loadingDetalle = false;
  },
  methods: {
    ...mapActions({
      updateCity: "cities/updateCity",
      getPaises: "paises/getPaises",
      getCity: "cities/getCity",
      nextEtpa: "detailCart/nextEtapa",
      getDetailCart: "detailCart/getDetailCart",
      sendDespacho: "etapas/sendDespacho",
      sendEmailStep: "etapas/sendEmailStep",
      postRetroceder: "etapas/postRetroceder",
      createPago: "carts/createPago",
      replaceShowModalDetailCart: "carts/replaceShowModalDetailCart",
    }),
    closeBoleta() {
      this.dialogBoleta = false;
    },
    boletaFormOpen() {
      this.form.num_documento = this.detailCart.pago.num_documento;
      this.form.num_seguimiento = this.detailCart.num_seguimiento;      
    },
    formatMoney(numero) {
      return new Intl.NumberFormat(["ban", "id"]).format(numero);
    },
    getData(id) {
      let url = `${HOST}/carros/${id}/etapas`;
      axios.get(url).then((response) => {
        this.carts = response.data.data;
        this.e6 = this.carts.length;
      });
    },
    async anularCarro() {
      this.anularCarroLoading = true;
      let id = this.id;
      let url = `${HOST}/carros/${id}/anular`;
      const res = await axios.delete(url);
      this.$message.warning("La orden fue anulada exitosamente");
      await this.getDetailCart({ cartId: this.id });
      await this.getData(this.id);
      this.anularCarroLoading = false;
    },
    async pagarCarro() {
      this.pagoManualLoading = true;
      let id = this.id;
      const data = { monto: this.monto, notas: this.justificacion };
      await this.createPago({ id: this.id, data });
      await this.getDetailCart({ cartId: this.id });
      await this.getData(this.id);
      this.pagoManualLoading = false;
      this.dialogPago = false;
    },
    async retiroTiendaEmail() {
      this.loadingEmail = true;
      let id = this.id;
      const data = { tipo: "Retiro", carrosId: [id] };
      let url = `${HOST}/carros/notificaciones`;
      const res = await axios.post(url, data);
      if (this.e6 < 5 && this.e6 == 3) {
        this.nextEtpa({ id: this.id, data: this.form })
          .then((response) => {
            this.e6 = this.e6 + 1;
            this.$message.success("Email enviado exitosamente");
          })
          .catch((error) => {
            this.processingForm = false;
            this.formErrors = error.response.data.errors || {};
          });
      }
      this.loadingEmail = false;
      await this.getDetailCart({ cartId: this.id });
      await this.getData(this.id);
    },
    async generarOT() {
      try {
        this.loadingOT = true;
        let id = this.id;
        let url = "";
        if (this.detailCart.courier.nombre === "Chilexpress") {
          url = `${HOST}/carros/${id}/chile-express/envios`;
        }
        else if (this.detailCart.courier.nombre === "Wareclouds") {
          url = `${HOST}/carros/${id}/wareclouds/envios`;
        }
        const res = await axios.post(url);
        this.form.num_seguimiento = res.data.data.numero_orden;
        if (this.e6 < 5 && this.e6 == 3) {
          this.nextEtpa({ id: this.id, data: this.form })
            .then((response) => {
              this.processingForm = false;
              this.e6 = this.e6 + 1;
              if (this.detailCart.courier.nombre === "Chilexpress") {
                this.sendEmailEtapa(4);
              }
            })
            .catch((error) => {
              this.processingForm = false;
              this.formErrors = error.response.data.errors || {};
            });
        }
      } catch (error) {
        console.log(error);
      }
      await this.getDetailCart({ cartId: this.id });
      await this.getData(this.id);
      this.loadingOT = false;
    },
    abrirOT() {
      this.form.num_seguimiento = this.detailCart.num_seguimiento;
      console.log(this.form.num_seguimiento);
    },
    closeModal() {
      this.form.num_documento = "";
      this.form.num_seguimiento = "";
      this.replaceShowModalDetailCart({ status: false });
    },
    async generarEtiqueta() {
      let id = this.detailCart.envio.id;
      let orden = this.form.num_seguimiento;
      //this.detailCart.envio.numero_orden;
      let tipo = this.tipo;
      let url = `${HOST}/envios/generar-impresion?numero_orden=${orden}&&tipo=${tipo}`;
      const res = await axios.get(url);
      window.open(res.data.data.file_path);
    },
    async retroceder() {
      await this.postRetroceder({ id: this.id });
      this.e6 = this.e6 - 1;
      this.$message.warning("Precaución: Acaba de retroceder una etapa");
      await this.getDetailCart({ cartId: this.id });
      await this.getData(this.id);
    },
    async sendDataDespacho() {
      this.loadingBoleta = true;
      await this.sendDespacho({ id: this.id, data: this.form });
      if (this.e6 < 5 && this.e6 == 2) {
        this.nextEtpa({ id: this.id, data: this.form })
          .then((response) => {
            this.e6 = this.e6 + 1;
            this.$message.success(
              "Información guardada exitosamente y se avanzó el carro a la siguiente etapa"
            );
            this.sendEmailEtapa(3);
          })
          .catch((error) => {
            this.processingForm = false;
            this.formErrors = error.response.data.errors || {};
          });
      }
      this.getDetailCart({ cartId: this.id });
      this.getData(this.id);
      //this.form.num_documento = "";
      this.loadingBoleta = false;
      this.dialogBoleta = false;
    },
    async generarBoleta() {
      this.loadingBoleta = true;
      let id = this.id;
      try {
        let url = `${HOST}/boleta/${id}`;
        const res = await axios.post(url);
        console.log(res);
        this.form.num_documento = res.data.data.boleta_id;
      } catch (error) {
        console.log(error);
      }
      if (this.e6 < 5 && this.e6 == 2) {
        this.nextEtpa({ id: this.id, data: this.form })
          .then((response) => {
            this.e6 = this.e6 + 1;
            this.$message.success(
              "Información guardada exitosamente y se avanzó el carro a la siguiente etapa"
            );
            this.sendEmailEtapa(3);
          })
          .catch((error) => {
            this.processingForm = false;
            this.formErrors = error.response.data.errors || {};
          });
      }
      await this.getDetailCart({ cartId: this.id });
      await this.getData(this.id);
      this.loadingBoleta = false;
    },
    async guardarOT() {
      this.loadingOT = true;
      this.form.num_documento = this.detailCart.pago.num_documento;      
      await this.sendDespacho({ id: this.id, data: this.form });
      this.$message.success("Número de seguimiento guardado exitosamente");
      if (this.e6 < 5 && this.e6 == 3) {
        this.nextEtpa({ id: this.id, data: this.form })
          .then((response) => {
            this.processingForm = false;
            this.e6 = this.e6 + 1;
            this.sendEmailEtapa(4);
          })
          .catch((error) => {
            this.processingForm = false;
            this.formErrors = error.response.data.errors || {};
          });
      }
      await this.getDetailCart({ cartId: this.id });
      await this.getData(this.id);
      this.loadingOT = false;
    },
    descargarBoleta() {
      window.open(res.data.data.file_path);
    },
    async sendEmailEtapa(etapa) {
      await this.sendEmailStep({ id: this.id, etapa: etapa });
      this.$message.success("E-mail enviado exitosamente");
    },
    async nextStep() {
      this.nextEtpa({ id: this.id, data: this.form })
        .then((response) => {
          this.processingForm = false;
          if (this.e6 < 5) {
            this.e6 = this.e6 + 1;
          }
        })
        .catch((error) => {
          this.processingForm = false;
          this.formErrors = error.response.data.errors || {};
        });
      this.getDetailCart({ cartId: this.id });
      this.getData(this.id);
    },
  },
};
</script>
<style>
.resumen-carro {
  display: block;
}

.resumen-carro-movil {
  display: none;
}

.panel-right {
  padding: 3px 1.5rem !important;
}
@media (max-width: 570px) {
  .container {
    padding: 8px 5px;
  }
  .resumen-carro {
    display: none;
  }
  .resumen-carro-movil {
    display: block;
  }
  .panel-right {
    padding: 5px !important;
  }
}
</style>
